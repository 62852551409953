import '../../App.css';
import {
  Box,
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
  Button,
} from '@mui/material';
import { Logo } from '../logo';
import { PhrasesBox } from '../Phrases';
import { useForm, Controller } from 'react-hook-form';
import { SelectDefault } from '../select';
import { CopyAllOutlined, CurrencyRuble, Percent } from '@mui/icons-material';
import { DefaultCheckbox } from '../checkbox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { dicountTypes, prices, tarifs, values } from '../../const';
import { capitalizeFirstLetter, copyToBuffer, numberToWords } from '../../helpers';
import { BackBtn } from '../back-btn';
import { CalcSelect } from '../calc-select';

export const Calculator = ({ isClientCalc = false, title = 'Калькулятор', phrases }) => {
  const { control, watch, setValue } = useForm({
    defaultValues: {
      businessType: values.businessType[0].value,
      orgType: values.orgType[0].value,
      snoType: values.snoType[0].value,
      discount: 0,
      ved: false,
      anotherProgramm: false,
      departmentsQuantity: 0,
      turnover: values.turnover[0].value,
      staff: 0,
      accounts: 1,
      operations: values.businessType[0].limit,
      markup: 1,
      patent: false,
      nds: false,
    },
  });

  const [discountType, setDiscountType] = useState('netto');

  const businessType = watch('businessType');
  const orgType = watch('orgType');
  const snoType = watch('snoType');
  const ved = watch('ved');
  const departmentsQuantity = watch('departmentsQuantity');
  const turnover = watch('turnover');
  const staff = watch('staff');
  const discount = watch('discount');
  const anotherProgramm = watch('anotherProgramm');
  const accounts = watch('accounts');
  const operations = watch('operations');
  const markup = watch('markup');
  const patent = watch('patent');
  const nds = watch('nds');

  const currentBusinessType = useMemo(
    () => values.businessType.find((type) => type.value === businessType),
    [businessType],
  );

  const [tarif, setTarif] = useState('standart');

  const [checkValue, setCheckValue] = useState(0);

  const checksum = useCallback(() => {
    const turnoverCoef = values.turnover.find((item) => item.value === turnover).coef;
    const staffCoef = values.staff.find(
      (item) => staff >= item.value[0] && staff <= item.value[1],
    ).coef;
    if (!turnoverCoef || !staffCoef) {
      return setCheckValue(0);
    }
    let value = prices[currentBusinessType.base];

    const limit = currentBusinessType.limit;
    const overOperations = operations - limit;
    value += overOperations * 15;
    value = patent ? value : value * values.orgType.find((item) => item.value === orgType).coef;
    value = patent ? value : value * values.snoType.find((item) => item.value === snoType).coef;
    value = value * turnoverCoef;

    if (departmentsQuantity > 0) {
      value += 3000;
      value += (departmentsQuantity - 1) * 2500;
    }

    value += (accounts - 1) * 1200;

    if (ved) {
      value = value * 1.25;
    }
    if (anotherProgramm) {
      value = value * 1.5;
    }

    value = staffCoef ? value + 1000 * staff * staffCoef : 0;

    if (nds) {
      value = value * 1.15;
    }

    setCheckValue(value);
  }, [
    currentBusinessType.base,
    currentBusinessType.limit,
    operations,
    patent,
    departmentsQuantity,
    accounts,
    ved,
    anotherProgramm,
    staff,
    nds,
    turnover,
    orgType,
    snoType,
  ]);

  useEffect(() => {
    checksum();
  }, [checksum]);

  const [checkDiscount, setCheckDiscount] = useState(0);

  useEffect(() => {
    setCheckDiscount((checkValue * markup * (100 - discount)) / 100);
  }, [checkValue, discount, markup]);

  useEffect(() => {
    setValue('operations', currentBusinessType.limit);
  }, [currentBusinessType, setValue]);

  const result = useMemo(() => {
    if (!checkValue)
      return 'Упс... Я пока не умею считать тарифы для Среднего бизнеса.\r\nОбратитесь к руководителю за пояснениями.';

    const value = Number(checkValue);
    const tariffName = tarifs[tarif].name;
    const businessTypeTitle = currentBusinessType.title;
    const orgTypeTitle = values.orgType.find((item) => item.value === orgType).title;
    const snoTypeTitle = values.snoType.find((item) => item.value === snoType).title;
    const turnoverTitle = values.turnover.find((item) => item.value === turnover).title;
    const discountSumm = checkDiscount * tarifs[tarif].coef;
    const discountToFixed = discountSumm.toFixed(2);
    const summWords = capitalizeFirstLetter(numberToWords(parseInt(discountSumm)));

    if (!isClientCalc)
      return `Коммерческое предложение для __\r\nТариф ${tariffName} ${
        value ? '— ' + (checkValue * tarifs[tarif].coef).toFixed(2) + ' рублей в месяц' : '-'
      }.${
        Boolean(+discount)
          ? `\r\nС учетом скидки стоимость составит ${
              value ? '— ' + discountToFixed + '  рублей в месяц' : '-'
            }.`
          : ''
      }\r\nПараметры тарифа:\r\nВид деятельности - ${businessTypeTitle}\r\nКол-во операций - ${operations}\r\nОрганизационно-правовая форма - ${orgTypeTitle}\r\nРежим налогообложения - ${snoTypeTitle}\r\nОбороты, млн. руб. - ${turnoverTitle}\r\nЧисленность сотрудников, чел. - ${staff}\r\nВЭД - ${
        ved ? 'Да' : 'Нет'
      }\r\nОбособленные подразделения - ${
        departmentsQuantity > 0 ? departmentsQuantity : 'Нет'
      }\r\nКоличество расчетных счетов - ${accounts}`;
    return (
      (discountType !== 'discount'
        ? `1.    Стороны договорились, что с ______________________г.\r\nЗаказчику устанавливается тариф ${tariffName} стоимостью ${
            value ? '— ' + discountToFixed : '-'
          } (${summWords} руб.${
            discountToFixed.split('.')[1]
          } коп) в месяц.\r\n1.1.    В тариф включено:\r\n`
        : `1.    Стороны договорились, что с ______________________г.\r\nЗаказчику устанавливается тариф ${tariffName}, с  учетом скидки в размере ${discount}%, стоимостью ${
            value ? '— ' + discountToFixed : '-'
          } (${summWords} руб. ${
            discountToFixed.split('.')[1]
          } коп) рублей в месяц.\r\n1.1.    В тариф включено:\r\n`) +
      `•    вид деятельности - ${businessTypeTitle};\r\n` +
      `•    количество операций, шт. – ${operations};\r\n` +
      `•    организационно-правовая форма – ${orgTypeTitle};\r\n` +
      `•    режим налогообложения - ${snoTypeTitle};\r\n` +
      `•    обороты, млн. руб. - ${turnoverTitle};\r\n` +
      `•    численность сотрудников, чел. – ${staff};\r\n` +
      `•    ВЭД – ${ved ? 'Да' : 'Нет'};\r\n` +
      `•    обособленные подразделения – ${
        departmentsQuantity > 0 ? departmentsQuantity : 'Нет'
      };\r\n` +
      `•    количество расчетных счетов, шт. – ${accounts}.\r\n`
    );
  }, [
    checkValue,
    isClientCalc,
    tarif,
    discount,
    checkDiscount,
    currentBusinessType.title,
    operations,
    staff,
    ved,
    departmentsQuantity,
    accounts,
    discountType,
    orgType,
    snoType,
    turnover,
  ]);

  useEffect(() => {
    ['discount', 'markup'].forEach((key, i) => setValue(key, i));
  }, [discountType, setValue]);

  useEffect(() => {
    if (snoType !== 3) return;
    setValue('nds', false);
  }, [setValue, snoType]);

  return (
    <Box className="App" sx={{ px: 8, py: 4, minHeight: '100dvh' }}>
      <Stack maxWidth="1312px" mx="auto" width={'100%'}>
        <Stack flexDirection="row" justifyContent="space-between" mb={'36px'} flexWrap="wrap">
          <Logo />
          <PhrasesBox phrases={phrases} />
        </Stack>
        <BackBtn />
        <Stack
          flexDirection="row"
          gap={2}
          mb={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h1" sx={{ fontSize: '32px', lineHeight: '36px', fontWeight: 700 }}>
            {title}
          </Typography>
          <CalcSelect />
        </Stack>
        <Stack flexDirection="row" gap={2}>
          <Box
            maxWidth="752px"
            display={'flex'}
            flexDirection="column"
            sx={{
              gap: 2,
              width: '100%',
              flex: 2,
            }}
          >
            <Stack gap={2} maxWidth="368px">
              <SelectDefault
                required
                name="businessType"
                control={control}
                items={values.businessType}
                label="Вид деятельности"
                sx={{ gridColumn: '1 / 3' }}
              />

              <FormGroup sx={{ gridColumn: '5 / 7' }}>
                <DefaultCheckbox control={control} name="ved" label="ВЭД" />
                <DefaultCheckbox control={control} name="anotherProgramm" label="Учет не в 1С" />
              </FormGroup>
              <Controller
                control={control}
                name="operations"
                render={({ field }) => (
                  <TextField
                    sx={{ gridColumn: '3 / 5' }}
                    type="number"
                    label={
                      <Box sx={{ span: { color: '#DA0E17' } }}>
                        Количество операций <span>*</span>
                      </Box>
                    }
                    {...field}
                    onChange={(e) => {
                      if (+e.target.value >= 0) field.onChange(e.target.value.replace(/\0-9/g, ''));
                    }}
                    onBlur={(e) => {
                      if (+e.target.value < 1) field.onChange(1);
                      if (!isClientCalc) {
                        const min = currentBusinessType.limit;
                        +e.target.value < min && field.onChange(min);
                      }
                      field.onBlur();
                    }}
                  />
                )}
              />
              <SelectDefault
                required
                name="orgType"
                control={control}
                items={values.orgType}
                label="Организационно правовая форма"
              />
              <SelectDefault
                required
                name="snoType"
                control={control}
                items={values.snoType}
                label="Система налогообложения"
              />
              <FormGroup>
                <DefaultCheckbox control={control} name="patent" label="Патент" />
              </FormGroup>
              <Controller
                control={control}
                name="departmentsQuantity"
                render={({ field }) => (
                  <TextField
                    type="number"
                    label={
                      <Box sx={{ span: { color: '#DA0E17' } }}>
                        Количество ОП <span>*</span>
                      </Box>
                    }
                    {...field}
                    onChange={(e) => {
                      if (+e.target.value >= 0) field.onChange(e.target.value.replace(/\0-9/g, ''));
                    }}
                    onBlur={(e) => {
                      if (+e.target.value < 0) field.onChange(0);
                      field.onBlur();
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="staff"
                render={({ field }) => (
                  <TextField
                    type="number"
                    label={
                      <Box sx={{ span: { color: '#DA0E17' } }}>
                        Численность сотрудников, чел.<span>*</span>
                      </Box>
                    }
                    {...field}
                    onChange={(e) => {
                      if (+e.target.value >= 0) field.onChange(e.target.value.replace(/\0-9/g, ''));
                    }}
                    onBlur={(e) => {
                      if (+e.target.value < 1) field.onChange(0);
                      field.onBlur();
                    }}
                  />
                )}
              />
              <SelectDefault
                required
                name="turnover"
                control={control}
                items={values.turnover}
                label="Оборот, млн. ₽"
              />

              <FormGroup>
                <DefaultCheckbox
                  disabled={snoType === 3}
                  control={control}
                  name="nds"
                  label="НДС"
                />
              </FormGroup>

              <Controller
                name="accounts"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    label={
                      <Box sx={{ span: { color: '#DA0E17' } }}>
                        Количество расчетных счетов<span>*</span>
                      </Box>
                    }
                    {...field}
                    onChange={(e) => {
                      field.onChange(+e.target.value < 0 ? 0 : e.target.value);
                    }}
                    onBlur={(e) => {
                      if (+e.target.value < 1) field.onChange(1);
                      field.onBlur();
                    }}
                  />
                )}
              />

              <Box sx={{ fontSize: '12px', color: '#9FA4BB', width: '100%', gridColumn: '1 / -1' }}>
                <span style={{ color: '#DA0E17' }}>*</span> - обязательные поля
              </Box>
            </Stack>
          </Box>
          <Box sx={{ borderLeft: '1px dashed #9FA4BB', width: '1px' }} />
          <Box
            sx={{
              flex: 0.8,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxWidth: '528px',
            }}
          >
            <RadioGroup
              sx={{
                gridColumn: '1/-1',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              {Object.entries(tarifs).map(([key, val]) => {
                return (
                  <Box key={'tarif' + key} display={'flex'} gap={2}>
                    <TextField
                      sx={{ flex: 1 }}
                      value={Number(checkValue) ? (val.coef * checkDiscount).toFixed(2) : '-'}
                      label={
                        <Box sx={{ span: { color: '#DA0E17' } }}>
                          {val.name} <span>*</span>
                        </Box>
                      }
                      InputProps={{
                        readOnly: true,
                        endAdornment: <CurrencyRuble sx={{ color: '#9FA4BB', fontSize: '22px' }} />,
                      }}
                    />
                    <FormControlLabel
                      sx={{ flex: 1, m: 0 }}
                      value={key}
                      control={<Radio />}
                      label={'Выбрать'}
                      checked={key === tarif}
                      onChange={(e) => setTarif(e.target.value)}
                    />
                  </Box>
                );
              })}
            </RadioGroup>
            {!isClientCalc ? (
              <Controller
                name="discount"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby="radio-discount"
                    sx={{
                      gridColumn: '1/-1',
                      display: 'grid',
                      gap: 2,
                      gridTemplateColumns: 'repeat(2, 1fr)',
                    }}
                  >
                    {values.discount.map((item) => (
                      <FormControlLabel
                        key={item.value + 'discount'}
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            ) : (
              <Box display="flex" gap={2} flexDirection="column-reverse">
                <Controller
                  key={discountType}
                  control={control}
                  name={discountType}
                  render={({ field }) => (
                    <TextField
                      sx={{ width: 'calc(50% - 8px)' }}
                      type="number"
                      disabled={discountType === 'netto'}
                      InputProps={{
                        endAdornment:
                          discountType === 'discount' ? (
                            <Percent sx={{ color: '#9FA4BB', fontSize: '22px' }} />
                          ) : null,
                        sx: {
                          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                            {
                              display: 'none',
                            },
                          '& input[type=number]': {
                            MozAppearance: 'textfield',
                          },
                        },
                      }}
                      label={
                        discountType !== 'netto' ? (
                          <Box sx={{ span: { color: '#DA0E17' } }}>
                            {discountType === 'discount' ? 'Процент скидки' : 'Коэффициент наценки'}{' '}
                            {<span>*</span>}
                          </Box>
                        ) : null
                      }
                      {...field}
                      onChange={(e) => {
                        if (+e.target.value >= 0)
                          field.onChange(e.target.value.replace(/\0-9/g, ''));
                      }}
                    />
                  )}
                />
                <RadioGroup
                  aria-labelledby="radio-discount"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                  }}
                  value={discountType}
                  onChange={(e) => setDiscountType(e.target.value)}
                >
                  {dicountTypes(isClientCalc).map(([key, name]) => (
                    <FormControlLabel
                      key={key + 'discount'}
                      value={key}
                      control={<Radio />}
                      label={name}
                      sx={{ width: 'max-content', m: 0 }}
                    />
                  ))}
                </RadioGroup>
              </Box>
            )}
            <TextField
              rows="auto"
              key={result}
              label={
                <Box sx={{ backgroundColor: '#F2F3F8', px: 0.5 }}>
                  {isClientCalc ? 'Для дополнительного соглашения:' : 'Для ТКП:'}
                </Box>
              }
              multiline
              sx={{ flex: 1, height: '100%' }}
              value={result}
              InputProps={{
                sx: {
                  flex: 1,
                  alignItems: 'start',
                  fontSize: '10pt',
                  textarea: {
                    height: '100%',
                  },
                },
              }}
            />
            <Button
              startIcon={<CopyAllOutlined />}
              sx={{ textTransform: 'none' }}
              variant="outlined"
              onClick={() => copyToBuffer(result)}
            >
              Копировать
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
