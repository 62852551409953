import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

export const DefaultCheckbox = ({ name, control, label, ...rest }) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            checked={field.value}
            onChange={(e) => field.onChange(e.target.checked)}
          />
        }
        {...rest}
        label={label}
      />
    )}
  />
);
