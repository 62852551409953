import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { Calculator } from './components/Calculator/Calculator';
import { phrasesClient, phrasesSales } from './const';
import MediumCalculator from './components/MediumBuisness/MediumBuisness';
import Dop from './components/dop/dop';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Navigate to="/sales" replace />,
    },
    {
      path: '/sales',
      element: <Calculator title="Расчет тарифов для малого бизнеса" phrases={phrasesSales} />,
      errorElement: <h1>404</h1>,
    },
    {
      path: '/client',
      element: (
        <Calculator
          isClientCalc
          title="Расчет переподписки для клиентского сервиса"
          phrases={phrasesClient}
        />
      ),
      errorElement: <h1>404</h1>,
    },
    {
      path: '/medium',
      element: <MediumCalculator phrases={phrasesSales} />,
    },
    {
      path: '/dop',
      element: <Dop phrases={phrasesSales} />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
